import React, { Component } from "react";
class VendorApiDocs extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="container-fluid mt-3">
        {/* Heading part */}
        <div>
          <h3>Nimbuzz Pay Payment Gateway API Documentation</h3>
          <div className="text-right">
            <a href="/NimbuzzPaymentGatewayDocs.pdf" download>
              Click to Download PDF
            </a>
          </div>
          <div className="mt-3">
            <p className="font-weight-light">
              Authentication for our system is handled by two headers:{" "}
              <span className="font-weight-bold">'api-key'</span>
              and <span className="font-weight-bold">'api-secret'</span>. These
              headers are essential for ensuring secure access to our services.
              To obtain the necessary API key, you can reach out to Nimbuzz.
              Nimbuzz will provide you with the specific API key required for
              authentication. Once you have the API key, you can include it in
              the 'api-key' header when making requests to our system.
              Similarly, the 'api-secret' header should also be included with
              the corresponding secret associated with your API key.
            </p>
          </div>
        </div>

        {/* API PART */}
        <div className="mt-4">
          <h3>Endpoints</h3>
          <ol className="font-weight-light">
            <li>
              <span className="font-weight-bold mr-1">
                Create Payment Intent:-
              </span>
              {`{{ url }}`}/api/v4/vendor/intent/create
            </li>
            <li>
              <span className="font-weight-bold mr-1">
                Check Transaction Status:-
              </span>
              {`{{ url }}`}/api/v4/vendor/intent/transaction?intent=
              {`{intentId} `}
            </li>
            <li>
              <span className="font-weight-bold mr-1">
                Get All Transactions:-
              </span>
              {`{{ url }}`}/api/v4/vendor/transactions
            </li>
          </ol>
        </div>

        {/* VENDOR PAYMENT INTENT */}
        <div className="mt-5 border-bottom-2 pb-3">
          <h5>
            <span
              className="font-weight-bold mr-2"
              style={{ color: "#ffa500" }}
            >
              {" "}
              POST
            </span>{" "}
            Create Intent Payment
          </h5>

          <div className="border mt-3 rounded font-weight-light p-1 overflow-auto">
            <p>{`{{ url }}`}/api/v4/vendor/intent/create</p>
          </div>
          <div className="mt-4 description">
            <h6> Description</h6>
            <p className="font-weight-light">
              The Payment intent helps with safe and easy money transactions in
              a system or app. This intent makes sure that money transfers
              happen smoothly while following rules for security. By using the
              Payment intent, users can buy things, send money, and do other
              financial tasks with confidence and convenience.
            </p>
          </div>
          <div className="mt-4 header border rounded p-1">
            <h6>Headers</h6>
            <div>
              <div className="p-2">
                <p>
                  <span>api-key</span>:{" "}
                  <span className="font-weight-light ml-1">{"{apikey}"}</span>
                </p>
                <p>
                  <span>api-secret</span>:{" "}
                  <span className="font-weight-light ml-1">
                    {"{apiSecret}"}
                  </span>
                </p>
              </div>
            </div>
          </div>
          {/* BODY */}
          <div className="mt-4 body border rounded p-1">
            <h6>
              Body<span className="font-weight-light ml-2">(raw json)</span>
            </h6>
            <div className="pl-2">
              <span>&#123;</span>
              <div className="p-2">
                <p>
                  <span style={{ color: "red" }}>"amount"</span>:{" "}
                  <span
                    className="font-weight-light ml-1"
                    style={{ color: "green" }}
                  >
                    10
                  </span>
                </p>
                <p>
                  <span style={{ color: "red" }}>"purpose"</span>:{" "}
                  <span
                    className="font-weight-light ml-1"
                    style={{ color: "green" }}
                  >
                    "Asali Himali Shampoo Payment"
                  </span>
                </p>
                <p>
                  <span style={{ color: "red" }}>"webhook"</span>:{" "}
                  <span
                    className="font-weight-light ml-1 "
                    style={{ color: "green" }}
                  >
                    "https://vendor.webapp/api/nimbuzz/webhook" // On
                    transaction event from customer, POST request is made to
                    webhook with JSON body identical to that of check
                    transaction API.
                  </span>
                </p>
                <p>
                  <span style={{ color: "red" }}>"vendorRedirectUrl"</span>:{" "}
                  <span
                    className="font-weight-light ml-1 "
                    style={{ color: "green" }}
                  >
                    "https://vendor.webapp/transaction-page" // redirect
                    customer to page after transaction event where intent is passed as query param (?intent=intentId).
                  </span>
                </p>
              </div>
              <span>&#125;</span>
            </div>
          </div>
          {/* RESPONSE */}
          <div className="mt-4 body border rounded p-1">
            <h6>
              Response<span className="font-weight-light ml-2">(raw json)</span>
            </h6>

            {/* RESPONSE DESCRIPTION */}
            <div className="pl-2">
              <p className="font-weight-light">
                If the status is{" "}
                <span className="font-weight-bold">"success"</span>, it
                indicates that the payment intent was created successfully.
              </p>
              <p className="font-weight-light">
                In the case of a successful payment intent, the redirect URL can
                be used to initiate the payment process on the NimbuzzPay
                platform. Additionally, the transaction event is updated to the
                <span className="font-weight-bold"> webhook</span> provided in intent. Make sure to verify intent passed on webhook and redirect url using check transaction status. The response provides the
                following details:
              </p>
              <small className="font-weight-bold text-align-justify">
                Please note that if the status is not{" "}
                <span className="font-weight-bold">"success"</span>, there may
                be an error or issue with the payment intent creation. In such
                cases, please refer to the accompanying error message or contact
                our support team for assistance.
              </small>
              <div>
                <span>&#123;</span>
                <div className="p-2">
                  <p>
                    <span style={{ color: "red" }}>"status"</span>:{" "}
                    <span
                      className="font-weight-light ml-1"
                      style={{ color: "green" }}
                    >
                      1
                    </span>
                    ,
                  </p>
                  <p>
                    <span style={{ color: "red" }}>"message"</span>:{" "}
                    <span
                      className="font-weight-light ml-1"
                      style={{ color: "green" }}
                    >
                      "Success"
                    </span>
                    ,
                  </p>
                  <p>
                    <span style={{ color: "red" }}>"data"</span>:{" "}
                    <div className="p-2">
                      <span>&#123;</span>
                      <div className="p-2">
                        <p>
                          <span style={{ color: "red" }}>"redirectUrl"</span>:{" "}
                          <span
                            className="font-weight-light ml-1"
                            style={{ color: "green" }}
                          >
                            {" "}
                            https://testing.nimbuzzpay.com/?intent=
                            {"{intentId}"}
                          </span>
                          ,
                        </p>
                        <p>
                          <span style={{ color: "red" }}>"intent"</span>:
                        </p>
                        <div className="pl-3">
                          <span>&#123;</span>
                          <div className="p-2">
                            <p>
                              <span style={{ color: "red" }}> "purpose"</span>:
                              <span
                                className="font-weight-light ml-1"
                                style={{ color: "green" }}
                              >
                                "Asali Himali Shampoo Payment"
                              </span>
                              ,
                            </p>
                            <p>
                              <span style={{ color: "red" }}> "_id"</span>:
                              <span
                                className="font-weight-light ml-1"
                                style={{ color: "green" }}
                              >
                                "647d967701ff3d371c9c227e"
                              </span>
                              ,
                            </p>
                            <p>
                              <span style={{ color: "red" }}> "amount"</span>:
                              <span
                                className="font-weight-light ml-1"
                                style={{ color: "green" }}
                              >
                                100
                              </span>
                              ,
                            </p>
                            <p>
                              <span style={{ color: "red" }}> "vendor"</span>:
                              <span
                                className="font-weight-light ml-1"
                                style={{ color: "green" }}
                              >
                                "6464658d33694f425528ea2f"
                              </span>
                              ,
                            </p>
                            <p>
                              <span style={{ color: "red" }}> "webhook"</span>:
                              <span
                                className="font-weight-light ml-1"
                                style={{ color: "green" }}
                              >
                                "https://test.vendor-backend.nimbuzz.co"
                              </span>
                              ,
                            </p>
                          </div>
                          <span>&#125;</span>
                        </div>
                      </div>
                      <span>&#125;</span>
                    </div>
                  </p>
                </div>
                <span>&#125;</span>
              </div>
            </div>
          </div>
        </div>

        {/* TRANSACTION STATUS */}
        <div className="mt-5 border-bottom-2 pb-3">
          <h5>
            <span
              className="font-weight-bold mr-2"
              style={{ color: "#008000" }}
            >
              {" "}
              GET
            </span>{" "}
            Check Transaction Status
          </h5>

          <div className="border mt-3 rounded font-weight-light p-1 overflow-auto">
            <p>
              {`{{ url }}`}/api/v4/vendor/intent/transaction?intent=
              <span className="font-weight-bold">&#123;intentId &#125;</span>
            </p>
          </div>
          <div className="mt-4 description">
            <h6> Description</h6>
            <p className="font-weight-light">
              This API allows you to retrieve the current status of a
              transaction in real-time. Simply provide the unique intent ID as a
              parameter to get the status.
            </p>
          </div>

          <div className="mt-4 header border rounded p-1">
            <h6>Headers</h6>
            <div className="p-2">
              <p>
                <span>api-key</span>:{" "}
                <span className="font-weight-light ml-1">{"{apikey}"}</span>
              </p>
              <p>
                <span>api-secret</span>:{" "}
                <span className="font-weight-light ml-1">{"{apiSecret}"}</span>
              </p>
            </div>
          </div>
          <div className="mt-4 response border rounded p-1">
            <h6>Response</h6>
            {/* RESPONSE DESCRIPTION */}
            <div className="pl-2">
              <p className="font-weight-light">
                If the status is{" "}
                <span className="font-weight-bold">"success"</span>, it
                indicates that the transaction was successful. The response
                provides the following details:
              </p>
              <small className="font-weight-bold text-align-justify">
                Please note that if the status is not{" "}
                <span className="font-weight-bold">"success"</span>, there may
                be an error or issue with the payment. In such cases, please
                refer to the accompanying error message or contact our support
                team for assistance.
              </small>
              <div>
                <span>&#123;</span>
                <div className="p-2">
                  <p>
                    <span style={{ color: "red" }}>"status"</span>:{" "}
                    <span
                      className="font-weight-light ml-1"
                      style={{ color: "green" }}
                    >
                      1
                    </span>
                    ,
                  </p>
                  <p>
                    <span style={{ color: "red" }}>"message"</span>:{" "}
                    <span
                      className="font-weight-light ml-1"
                      style={{ color: "green" }}
                    >
                      "Transaction detail"
                    </span>
                    ,
                  </p>
                  <p>
                    <span style={{ color: "red" }}>"data"</span>:{" "}
                    <div className="p-2">
                      <span>&#123;</span>
                      <div className="p-2">
                        <p>
                          <span style={{ color: "red" }}>"status"</span>:{" "}
                          <span
                            className="font-weight-light ml-1"
                            style={{ color: "green" }}
                          >
                            "success"
                          </span>
                          ,
                        </p>

                        <p>
                          <span style={{ color: "red" }}>"_id"</span>:{" "}
                          <span
                            className="font-weight-light ml-1"
                            style={{ color: "green" }}
                          >
                            "647d8b1c5d473d6aa9a1d0d4"
                          </span>
                          ,
                        </p>
                        <p>
                          <span style={{ color: "red" }}>"amount"</span>:{" "}
                          <span
                            className="font-weight-light ml-1"
                            style={{ color: "green" }}
                          >
                            79
                          </span>
                          ,
                        </p>
                        <p>
                          <span style={{ color: "red" }}>"rawParams"</span>:{" "}
                          <div className="pl-3">
                            <span>&#123;</span>
                            <div className="pl-2">
                              <p>
                                <span style={{ color: "red" }}>"intent"</span>:{" "}
                                <span
                                  className="font-weight-light ml-1"
                                  style={{ color: "green" }}
                                >
                                  "647d8b13b48ed9316a48cad1"
                                </span>
                                ,
                              </p>
                            </div>

                            <span>&#125;</span>
                          </div>
                        </p>
                      </div>
                      <span>&#125;</span>
                    </div>
                  </p>
                </div>
                <span>&#125;</span>
              </div>
            </div>
          </div>
          <div className="mt-4 body border rounded p-1">
            <h6>
              Query Params<span className="font-weight-light ml-2"></span>
            </h6>
            <p className="mt-1 p-2">
              intent
              <span className="font-weight-light ml-3">
                &#123;intentId&#125;
              </span>
            </p>
          </div>
        </div>
        {/* TRANSACTION STATUS */}
        <div className="mt-5 border-bottom-2 pb-3">
          <h5>
            <span
              className="font-weight-bold mr-2"
              style={{ color: "#008000" }}
            >
              {" "}
              GET
            </span>{" "}
            Get All Transactions
          </h5>

          <div className="border mt-3 rounded font-weight-light p-1 overflow-auto">
            <p>{`{{ url }}`}/api/v4/vendor/transactions?page=1</p>
          </div>
          <div className="mt-4 description">
            <h6> Description</h6>
            <p className="font-weight-light">
              This API allows authenticated users to retrieve their transaction
              history. It returns a paginated list of transactions associated
              with the vendor's account. The
              <span className="font-weight-bold"> page </span> parameter allows
              vendors to navigate through different pages of the transaction
              history.
            </p>
          </div>
          <div className="mt-4 header border rounded p-1">
            <h6>Headers</h6>
            <div>
              <div className="p-2">
                <p>
                  <span>api-key</span>:{" "}
                  <span className="font-weight-light ml-1">{"{apikey}"}</span>
                </p>
                <p>
                  <span>api-secret</span>:{" "}
                  <span className="font-weight-light ml-1">
                    {"{apiSecret}"}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="mt-4 body border rounded p-1">
            <h6>
              Query Params<span className="font-weight-light ml-2"></span>
            </h6>
            <p className="mt-1 p-2">
              <small className="mr-1">{`(optional)`}</small>page
              <span className="font-weight-light ml-3">&#123;pageno&#125;</span>
            </p>
          </div>
          <div className="mt-4 response border rounded p-1">
            <h6>Response</h6>
            {/* RESPONSE DESCRIPTION */}
            <div className="pl-2">
              <small className="font-weight-bold text-align-justify">
                Please note that if the status is not{" "}
                <span className="font-weight-bold"> "success" </span>, there may
                be an error or issue with the payment. In such cases, please
                refer to the accompanying error message or contact our support
                team for assistance.
              </small>
              <div>
                <span>&#123;</span>
                <div className="p-2">
                  <p>
                    <span style={{ color: "red" }}>"status"</span>:{" "}
                    <span
                      className="font-weight-light ml-1"
                      style={{ color: "green" }}
                    >
                      1
                    </span>
                    ,
                  </p>
                  <p>
                    <span style={{ color: "red" }}>"message"</span>:{" "}
                    <span
                      className="font-weight-light ml-1"
                      style={{ color: "green" }}
                    >
                      "Transactions"
                    </span>
                    ,
                  </p>
                  <p>
                    <span style={{ color: "red" }}>"data"</span>:{" "}
                    <div className="p-2">
                      <span>{"["}</span>
                      <div className="pl-3">
                        <span>&#123;</span>
                        <p>
                          <span style={{ color: "red" }}>"status"</span>:{" "}
                          <span
                            className="font-weight-light ml-1"
                            style={{ color: "green" }}
                          >
                            "success"
                          </span>
                          ,
                        </p>

                        <p>
                          <span style={{ color: "red" }}>"_id"</span>:{" "}
                          <span
                            className="font-weight-light ml-1"
                            style={{ color: "green" }}
                          >
                            "647d8b1c5d473d6aa9a1d0d4"
                          </span>
                          ,
                        </p>
                        <p>
                          <span style={{ color: "red" }}>"amount"</span>:{" "}
                          <span
                            className="font-weight-light ml-1"
                            style={{ color: "green" }}
                          >
                            10
                          </span>
                          ,
                        </p>
                        <span>&#125; ,</span>
                      </div>

                      <div className="pl-3">
                        <span>&#123;</span>
                        <p>
                          <span style={{ color: "red" }}>"status"</span>:{" "}
                          <span
                            className="font-weight-light ml-1"
                            style={{ color: "green" }}
                          >
                            "initiate"
                          </span>
                          ,
                        </p>

                        <p>
                          <span style={{ color: "red" }}>"_id"</span>:{" "}
                          <span
                            className="font-weight-light ml-1"
                            style={{ color: "green" }}
                          >
                            "647d8b1c5d473d6aa9a1d0d4"
                          </span>
                          ,
                        </p>
                        <p>
                          <span style={{ color: "red" }}>"amount"</span>:{" "}
                          <span
                            className="font-weight-light ml-1"
                            style={{ color: "green" }}
                          >
                            10
                          </span>
                          ,
                        </p>
                        <span>&#125;</span>
                      </div>
                      <span>{"]"}</span>
                    </div>
                  </p>
                </div>
                <span>&#125;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default VendorApiDocs;
